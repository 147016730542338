/* Smooth-scrolling START */
html {
  overflow-x: hidden;
  overflow-y: scroll;
}

.js-scroll {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  will-change: transform;
}

/* Smooth-scrolling END */


svg textPath {
  font-size: 24px;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 20px;
  fill: #111;
  background: #333;
}


/* animattiom  */

.text-animation {
  color: #333;
  /* font-size: 3rem; */
  font-weight: 100;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.text-animation span {
  font-size: 40px;
  margin-left: 40px;
}

.text-animation .message {
  /* background-color: yellow; */
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  /* padding-left: 0.5rem; */

  /* top: 0.2rem; */
  transform: translateY(0%);
  width: 830px;

  animation: openclose 10s ease-in-out infinite;
}

.text-animation .word1,
.word2,
.word3 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(-30%);
    opacity: 1;
  }

  50% {
    transform: translateY(-65%);
    opacity: 1;
  }

  75% {
    transform: translateY(-100%);
    opacity: 1;
  }

  100% {
    transform: translateY(-130%);
    opacity: 0.2;
  }
}

/* @keyframes openclose {
0% {
top: 0.2rem;
width: 0;
}
5% {
width: 0;
}
15% {
width: 830px;
}
30% {
top: 0.2rem;
width: 830px;
}
33% {
top: 0.2rem;
width: 0;
}
35% {
top: 0.2rem;
width: 0;
}
38% {
top: -4.5rem;

}
48% {
top: -4.5rem;
width: 830px;
}
62% {
top: -4.5rem;
width: 830px;
}
66% {
top: -4.5rem;
width: 0;
text-indent: 0;
}
71% {
top: -9rem;
width: 0;
text-indent: 5px;
}
86% {
top: -9rem;
width: 830px;
}
95% {
top: -9rem;
width: 830px;
}
98% {
top: -9rem;
width: 0;
text-indent: 5px;
}
100% {
top: 0;
width: 0;
text-indent: 0;
}
} */

/* TABBINATION WORK 
\ */

.season_tabs {
  position: relative;
  height: auto;
  /* This part sucks */
}

.ai-tabs {
  position: relative;
  min-height: auto;
  /* This part sucks */
  clear: both;
  margin: 20px 0;
}

.season_tab {
  /* width: 50%; */
  width: 100%;


}

.season_tab label {
  /* background: #eee; */
  /* padding: 10px; */
  margin-left: 0px;
  vertical-align: middle;
  position: relative;
  left: 1px;
  width: 20rem;
  height: 40px;
  display: table-cell;
  line-height: 3rem;
  cursor: pointer;
}

.season_tab .environment {
  display: flex;
}

.season_tab [type=radio] {
  display: none;
}

.season_content {
  position: absolute;
  top: 0;
  left: 40%;
  background: white;
  right: 0;
}

.season_content_horizontal {
  position: absolute;
  top: 0;
  left: 40%;
  background: white;
  right: 0;
}

.terra_tabs {
  position: relative;
  min-height: 450px;
  clear: both;
  margin: 20px 0;
}

.terra_tab {
  float: left;
  clear: both;
  width: 50%;
  width: 100%;
}

.terra_tab label {
  /* background: #eee; */
  /* padding: 10px; */
  margin-left: 0px;
  position: relative;
  left: 1px;
  width: 30rem;
  height: 40px;
  display: block;
}


.season_content span {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
}

[type=radio]:checked~label {
  background: white;
  border-bottom: 2px solid #5C3EB3;
  z-index: 2;
  color: #5C3EB3;
}

[type=radio]:checked~label~.season_content {
  z-index: 1;
}

[type=radio]:checked~.environment {
  background-color: transparent;
  border-bottom: 2px solid white;
  z-index: 2;
  color: white;
}

.environment-contant {
  background-color: #5C3EB3;
}

.season-tabs-environment {
  min-height: 320px;
}

.season-tabs-education {
  min-height: 400px;
}


/* clicked accordion slide effect animaion code  */
/* .grid {
display: grid;
}
.grid-cols-1 {
grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-4 {
grid-template-columns: repeat(4, minmax(0, 1fr));
}
.col-span-2:hover {
grid-column: span 2 / span 2;
grid-column-start: span 2;
grid-column-end: span 2;} */


.grid-split {
  cursor: pointer;
  grid-column: span 2 / span 2;
  grid-column-start: span 2;
  grid-column-end: span 2;
  background-color: #5C3EB3;
  color: white;
}

.show {
  display: none;
}

.grid-split .show {
  display: block;
}

.custom-btn-1 {
  /* width: 130px;
  height: 40px; */
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1); */
  outline: none;
}

/* button animation  */
/* banner-button  */
.custom-btn {
  /* width: 130px;
          height: 40px; */
  color: #fff;
  padding: 14px 25px;
  font-weight: 300;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
           7px 7px 20px 0px rgba(0,0,0,.1),
           4px 4px 5px 0px rgba(0,0,0,.1); */
  outline: none;
  font-size: 16px;
}

.custom-btn-transperent {
  /* width: 130px;
          height: 40px; */
  color: #fff;
  padding: 12px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
           7px 7px 20px 0px rgba(0,0,0,.1),
           4px 4px 5px 0px rgba(0,0,0,.1); */
  outline: none;
}

.btn-16 {
  background-color: transparent;
  color: black;               /* Comment For New Navbar */ 
  border: 1px solid black;    /* Comment For New Navbar */ 
  /* color: #fff; 
  border: 1px solid #fff;  */
  z-index: 1;
  font-weight: 500;
  font-family: Gilroy-Medium;
}

.new_navbar_theme .btn-16 {
  background-color: transparent;
  /* color: black; */               /* Comment For New Navbar */ 
  /* border: 1px solid black; */    /* Comment For New Navbar */ 
  color: #fff; 
  border: 1px solid #fff; 
  z-index: 1;
  font-weight: 500;
  font-family: Gilroy-Medium;
}


.new_btn_hire{
  background: #5c3eb3;
}
.new_btn_hire:hover{
  background: transparent;
  color: #fff;
}

.new_btn_hire:hover::after {
  left: 0;
  width: 100%;
}
.new_btn_hire::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-image: linear-gradient(to right, #6b21a8, #8023b4, #9525bf, #aa26c9, #c026d3);
  border-radius: 5px;
  /* box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1); */
  transition: all 0.3s ease;
}


.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0px;
  right: 0;
  z-index: -1;
  background-image: linear-gradient(to right, #6b21a8, #8023b4, #9525bf, #aa26c9, #c026d3); /* Comment For New Navbar */ 
  /* border-radius: 5px; */
  /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
           7px 7px 20px 0px rgba(0,0,0,.1),
           4px 4px 5px 0px rgba(0,0,0,.1); */
  transition: all 0.3s ease;
}

.new_navbar_theme .btn-16:hover::after{
  display: none;
}
.new_navbar_theme .btn-16:hover {
  color: #fff;
  border: 1px solid #fff
  /* border: 1px solid transparent; */

}

.btn-16:hover:after {
  /* left: 0; */
  width: 100%;
}

.btn-16:active {
  top: 2px;
}

/* 111111 */



/* 2222 */

.btn-15 {
  background: #5C3EB3;
  border: none;
  z-index: 1;
}


.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-image: linear-gradient(to right, #6b21a8, #8023b4, #9525bf, #aa26c9, #c026d3);
  border-radius: 5px;
  /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
           7px 7px 20px 0px rgba(0,0,0,.1),
           4px 4px 5px 0px rgba(0,0,0,.1); */
  transition: all 0.3s ease;
}

.btn-15:hover {
  color: #fff;
}

.btn-15:hover:after {
  /* left: 0; */
  width: 100%;
}

.btn-15:active {
  top: 2px;
}




.btn-17 {
  background: transparent;
  border: none;
  z-index: 1;
}

.btn-border {
  border: 1px solid white;
}

.btn-border:hover {
  border: 1px solid #fff;
}

.btn-17:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: white;

  /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
           7px 7px 20px 0px rgba(0,0,0,.1),
           4px 4px 5px 0px rgba(0,0,0,.1); */
  transition: all 0.3s ease;
}

.btn-17:hover {
  color: rgb(132, 0, 255);

}

.btn-17:hover:after {
  /* left: 0; */
  width: 100%;
}

.btn-17:active {
  top: 2px;
}




.btn-18 {
  border: none;
  z-index: 1;
}

.btn-18:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: white;
  transition: all 0.3s ease;
}

.btn-18:hover {
  color: rgb(153, 6, 251);
}

.btn-18:hover:after {
  width: 100%;
}

.btn-18:active {
  top: 2px;
}

.custom-btn_hire {
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
}


/* CARD SLIDER TEXT ANIMATION  */
.newsCard {
  position: relative;
  width: 100%;
  cursor: pointer;
}


.mask {
  position: absolute;
  bottom: -5rem;

  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.61), transparent);
  /* background-color: rgba(0, 0, 0, 0.511); */
  overflow: hidden;
  width: 100%;
  height: 30%;
  transition: .5s ease;
}

.anim-plug:hover .mask {
  bottom: 0;
}

.text {
  color: white;
  font-size: 20px;
}

.text-2 {
  color: black;
  font-size: 20px;
  position: absolute;
  padding: 2rem;
}

.centerContent {
  left: 50%;
  top: 50%;
  position: absolute;
}

.newsCard .image {
  object-fit: fill;
}

/* .arrowImage {
  width: 20px;
  height: 4px;
} */


/* slide animate one */
.container-con {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f2f0ff;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.container-con:hover .overlay {
  height: 100%;
}


/* slide animate two */
.overlay2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #E8E2F7;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .3s ease;
  display: grid;
  justify-content: center;
}

.container-con:hover .overlay2 {
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.text3 {
  color: black;
  font-size: 20px;
  position: absolute;
  padding: 1rem;
}




.customContainer {
  width: 1380px;
}



.show-on-hover {
  visibility: hidden;
  opacity: 0.0;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: all 0.6s;

}

#slideDown:hover .show-on-hover {
  opacity: 1.0;
  visibility: visible;
}




/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

.scrolling-words-container {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
}

.scrolling-words-box {
  height: 2.5rem;
  margin: auto;
  overflow: hidden;
}

.scrolling-words-box ul {
  /* margin: 0 0.625rem; */
  padding: 0;
  animation: scrollUp 5s infinite;

}

.scrolling-words-box ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
  list-style: none;
  color: #aac77b;
  font-family: Gilroy-Bold;
  letter-spacing: 1px;
}

@keyframes scrollUp {

  10%,
  20% {
    transform: translateY(-16.66%);
  }

  30%,
  40% {
    transform: translateY(-32.32%);
  }

  50%,
  60% {
    transform: translateY(-49.98%);
  }

  70%,
  80% {
    transform: translateY(-66.64%);
  }

  90%,
  100% {
    transform: translateY(-83.3%);
  }
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* image marquee  */
.slider {
  height: 70px;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}

/* .slider::before,
.slider::after{
  position:absolute;
  background-image:linear-gradient(to right,rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  content:'';
  height:100%;width:2%;
  z-index:2;pointer-events:none;
} */
.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/*  IMPORTANT CODE BELOW */

.slide-track {
  width: calc(150px * 42);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-evenly;
}

.slide-track2 {
  width: calc(150px * 42);
  display: flex;
  animation: scroll2 15s linear infinite;
  justify-content: space-evenly;
}

.slide {
  width: 150px;
  height: 60px;

  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
}

/* this is old animation in main page logo hover animation */

/* .slide:hover {
  transform: scale(0.8)
} */

/* this is old animation in main page logo hover animation */


/* karan code start*/
  .slide:hover {transform: scale(1);}

  .slider_bg{
    background-position: center center !important; 
    background-size: cover !important; 
    background-repeat: no-repeat !important; 
    background-attachment: fixed !important; 
    max-width: 100% !important; 
    height: auto; 
    /* padding: 80px; */
    height: 35vh;
  }
  
/* karan code finish */


/* Karan Home Page Css Start */

/* .skeleton {
 opacity: 0.7;
  animation: 1s shine linear infinite;
} */






.slider-cards {
  display: flex;
}

.slider-card {
  /* margin: 10px; */
  /* width: 300px; */
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.slider-card .image img {
  max-width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.slider-card .content {
  padding: 10px 10px;
}

.slider-card.is-loading .image,
.slider-card.is-loading h2,
.slider-card.is-loading .timeline,
.slider-card.is-loading .author-img,
.slider-card.is-loading .author-name,
.slider-card.is-loading .date,
.slider-card.is-loading .timing,
.slider-card.is-loading p {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  /* border-radius: 5px; */
  background-size: 200% 100%;
  -webkit-animation: 1.5s shine linear infinite;
          animation: 1.5s shine linear infinite;
}

.slider-card.is-loading .author-name{width: 55%;}
.slider-card.is-loading .timing{width: 55%;}




.slider-card.is-loading .image {
  height: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  
}
.slider-card.is-loading h2 {
  height: 50px;
}
.slider-card.is-loading p ,.slider-card.is-loading .timing{
  height: 30px;
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


/* Karan Home Page Css Finish */



/* Karan Careers Card Start */

#position_box .bg-violet:hover .simple_vaccancy{
background: #6B4AC8;
}
.simple_vaccancy{
  height: 320px;
  position: relative;
  /* overflow-y: auto; */
}
.simple_vaccancy h3{
  color: #AAC77B;
}



.Apply-Career {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 30%;
  left: 50%;
  margin: 20px auto;
  /* display:  none;*/ /*Initially hidden */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.loader_data{
  display: block;
}

/* Karan Careers Card Finish */










/* Karan New Client-Portfolio Start */

/* .page_container{
  max-width: 1240px;
} */


.text_blue{color: #1C033D;}
/* .bg_blue{background: #1C033D;} */

.pfF_first_section{
  background: url(/src/images/portfolio/top_bg_portfolio.webp) no-repeat top center / cover;
}



.marquee_parent {
  width: 100%;
  overflow-x: hidden;
}
.marquee_items_parent {
  display: flex;
  /* font-size: 40px; */
  width: 100%;
  animation-name: infiniteScroll;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.marquee_items_parent {
  white-space: nowrap;
}


.bg_purple{
  background: url(/src/images/portfolio/purple_background.webp) no-repeat center center / cover;
}



.gradient_title{
  background: linear-gradient(180deg, #f9f9f9, #ffffff, #fffcfc) padding-box, linear-gradient(150deg, #F98EDB 40%, #fff 60%) border-box;
  color: #313149;
  padding: 13px 36px;
  border: 1px solid transparent;
  border-radius: 40px;
  display: inline-block;

}




.core_team_parent{background: linear-gradient(#ffffff00 17%, #ffffff 40%), url(/src/images/portfolio/line_transparent.webp);}

.core_team{ border: 3px solid transparent; background: url(/src/images/portfolio/core_team_bg.webp),linear-gradient(0deg, #323232 0%, #000000 100%) padding-box, linear-gradient(135deg, #423FCC 2%, #FF7F56C1 31%, #323232 64%) border-box; box-shadow: 0px 30px 40px 0px #13083147; background-repeat: no-repeat; background-size: cover;/* background: linear-gradient(0deg, #323232 0%, #000000 100%); */ }

.circle_parent .circle_item:nth-child(odd) .circle_gradient { background: linear-gradient(90deg, #272727, #141414) padding-box, linear-gradient(87deg, #414141 40%, #fff0 55%) border-box; /* background: linear-gradient(90deg, #272727, #141414) padding-box, linear-gradient(72deg, #ffffff 34%, #fff0 40%) border-box; */}

.circle_parent .circle_item:nth-child(even) .circle_gradient {background: linear-gradient(270deg, #272727, #141414) padding-box, linear-gradient(272deg, #414141 47%, #fff0 55%) border-box}

.circle_parent .circle_item:nth-child(odd)::after   { position: absolute; content: ''; height: 1px; width: 150%; background: #414141; z-index: 9; left: 44%; bottom: -0.4px; /* bottom: 0.6px; */ }

.circle_parent .circle_item:nth-child(even)::before { position: absolute; content: ''; height: 1px; width: 150%; background: #414141; z-index: 9; left: 44%; bottom: 0.6px; top: -0.4px;}

.circle_parent .circle_item:last-child::before,.circle_parent:last-child::after{display: none;}

.circle_gradient{ text-wrap: nowrap; padding: 67px 0px; border: 1px solid transparent;}




.pepsico{ background-image: url(/src/images/portfolio/line_transparent.webp), linear-gradient(#F7F1FB, #F7F1FB); background-repeat: no-repeat; background-position: 11% 0%;}

.fountain_life{ background-image: url(/src/images/portfolio/fountain_background.webp); background-repeat: no-repeat; background-position: 90% 0%; background-size: contain;}

.shl{ background-image: url(/src/images/portfolio/line_transparent.webp), linear-gradient(#F7F1FB, #F7F1FB); background-repeat: no-repeat; background-position: 85% 0%;}

.safehouse{ background-image: url(/src/images/portfolio/safehouse_background.webp), linear-gradient(#fff, #fff); background-repeat: no-repeat; background-position: 21% 0%; background-size: contain;}

.worship{ background-image: url(/src/images/portfolio/worship_background.webp), linear-gradient(#F7F1FB, #F7F1FB); background-repeat: no-repeat; background-position: 87% -31%;}

.myysports{ background-image: url(/src/images/portfolio/line_transparent.webp), linear-gradient(#ffffff, #ffffff); background-repeat: no-repeat; background-position: 11% 0%;}

.coinigy{ background-image: url(/src/images/portfolio/fountain_background.webp); background-repeat: no-repeat; background-position: 80% 0%; background-size: contain;}

.jasiri{ background-image: url(/src/images/portfolio/line_transparent.webp), linear-gradient(#F7F1FB, #F7F1FB); background-repeat: no-repeat; background-position: 85% 0%;}

.eseo{ background-image: url(/src/images/portfolio/line_transparent.webp), linear-gradient(#ffffff, #ffffff); background-repeat: no-repeat; background-position: 11% 0%;}

.housing_marking{ background-image: url(/src/images/portfolio/safehouse_background.webp), linear-gradient(#fff, #fff); background-repeat: no-repeat; background-position: 70% 0%; background-size: contain;}

.chargever{ background-image: url(/src/images/portfolio/worship_background.webp), linear-gradient(#F7F1FB, #F7F1FB); background-repeat: no-repeat; background-position: 0% -45%;}

.obodo{ background-image: url(/src/images/portfolio/line_transparent.webp), linear-gradient(#ffffff, #ffffff); background-repeat: no-repeat; background-position: 85% 0%;}

.swift_digital{ background-image: url(/src/images/portfolio/fountain_background.webp); background-repeat: no-repeat; background-position: 10% 0%; background-size: contain;}

.smogwise{ background-image: linear-gradient(#F5F5F57D, #F5F5F57D)}

.learnloft{ background-image: url(/src/images/portfolio/learnloft_background.webp), linear-gradient(#010101, #000); background-repeat: no-repeat; background-position: 95% 43%;}

.adapt{ background-image: url(/src/images/portfolio/fountain_background.webp); background-repeat: no-repeat; background-position: 80% 0%; background-size: contain;}

.quixy_hamta{ background-image: linear-gradient(#F5F5F57D, #F5F5F57D)}

.shl_black{ background-image: url(/src/images/portfolio/shl_background.webp), linear-gradient(#010101, #000); background-repeat: no-repeat; background-position: 15% 0%; background-size: contain;}

.delivery_result{background: linear-gradient(to bottom right, #1C033D, #0F0221D9);  }

/* .circle_purple{ height: 801px; width: 801px; background: linear-gradient(to bottom right, #1C033DD9, #0F0221D9); border-radius: 50%; right: -90px; top: -150px; z-index:-1; border: 2px solid transparent;} */

.circle_purple{ height: 801px; width: 801px; background: linear-gradient(to bottom right, #1C033DD9, #0F0221D9) padding-box, linear-gradient(87deg, #797979d9 -160%, #fff0 70%) border-box; border-radius: 50%; right: -90px; top: -150px; z-index:-1; border: 2px solid transparent;}
  
.sm_circle{ background: linear-gradient(221deg, #1C033DD9 60%, #0F0221D9 90%) padding-box, linear-gradient(87deg, #797979d9 -160%, #fff0 70%) border-box; border: 2px solid transparent;}

.circle_dark{ height: 801px; width: 801px; background: linear-gradient(360deg,#0c0c0c, #121212) padding-box, linear-gradient(87deg, #000000 63%, #434343 70%) border-box; border-radius: 50%; left: -40px; top: -200px; z-index:-1; border: 1px solid transparent;}

.track_record{background: #000000 }




.apps_title::before{ content: ''; position: absolute; top: 18px; left: 145px; background-color: #3E3E3E; height: 1px; width: 19%;}

.learnloft .apps_title::before{  background-color: #fff;}

.fountain_life .apps_title_2::before{ content: ''; position: absolute; top: 18px; left: 252px; background-color: #3E3E3E; height: 1px; width: 19%;}

.jasiri .apps_title_2::before{ content: ''; position: absolute; top: 18px; left: 252px; background-color: #3E3E3E; height: 1px; width: 19%;}

.smogwise .apps_title_2::before{ content: ''; position: absolute; top: 18px; left: 252px; background-color: #3E3E3E; height: 1px; width: 19%;}


@keyframes infiniteScroll {
  from {transform: translateX(0)}
  to {transform: translateX(-50%)}
}
/* Karan New Client-Portfolio Finish */

















@keyframes scroll {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-150px * 5));
  }
}

/* android card slide animation */
.customSlide {
  /* display: block; */
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all .2s;
}

.customSlide:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom center;
  background: #5c3eb3;
  z-index: -1;
  transition: transform 0.2s;
}

.customSlide:hover:after {
  transform: scaleY(1);
  transition: 0.35s all;
}



/* = */
.custom_list_item li::before {
  content: url('../images/red-circle.svg');
}

.custom_list_item li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.7rem;
}

/* == */

.containerCustom {
  position: relative;
  width: 100%;
}

.space-holder {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.stickyCustom {
  position: sticky;
  top: 10rem;
  height: 80vh;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
  overflow-y: hidden;
}

.horizontal {
  position: absolute;
  will-change: transform;
}

.cards {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.sample-card {
  position: relative;
  width: 430px;
  margin-right: 20px;
  display: grid;
}

/* .sample-card .bg-purple-white{} */
.accordion__content ul {
  padding-left: 1rem;
  margin-bottom: 1rem;
}





  .mnre_invert{
    filter: invert(1);
  }
  

@media screen and (min-width: 0px) and (max-width: 456px){
  .mask {
    position: absolute;
    bottom: 0rem
  }

  .mnre_invert{
    filter: invert(0);
  }
  
  .slider_bg{
    /* padding: 80px !important; */
    height: 45vh;
  }
}



@media screen and (max-width: 768px) {
  
  .custom-btn {
    padding: 10px 20px;
  }

  .custom-btn-transperent {
    padding: 10px 20px;
  }
  .customContainer {
    width: 100%;
  }



  .slide-track {
    width: calc(150px * 25);
  }

  .slide-track2 {
    width: calc(150px * 25);
  }

  .slide {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(calc(-80px * 10));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(calc(-80px * 5));
    }
  }

  .scrolling-words-container {
    font-size: 1.3rem;
  }

  .scrolling-words-box {
    height: 2rem;
  }

  .scrolling-words-box ul li {
    height: 2rem;
  }

  .slider_bg{
    /* padding: 100px; */
    height: 40vh;
  }
  
}


@media screen and  (max-width:991px) {
  .stickyCustom {
    position: sticky;
    top: 0;
    height: 100vh;
  }

  .bg-contact-convo form label {
    display: inline-block;
  }

  .bg-contact-convo form input {
    margin-bottom: 1rem;
  }

  .accordion__content ul {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  /* .sample-card {
    position: relative;
    width: 300px;
    margin-right: 20px;
    display: grid;
  } */



  /* karan css start */
  
  .sample-card {
    position: relative;
    min-width: 360px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: grid;
  }
 



  /* karan css Finish */


  
  /* .sample-card .bg-purple-white{min-height: 250px;} */
}


@media screen and (max-width:1024px) {
  .newsCard .image {
    min-height: 220px;
  }
}


@media screen and (max-width: 1366px) {
  .stickyCustom {
    position: sticky;
    top: 6rem;
    height: 100vh;
  }

  /* Karan Css Class Start*/

  .containerCustom > .space-holder >.stickyCustom{
    position: sticky;
    top: 4rem;
    height: 90vh;
  }
  
  
  /* brand animation finish */
  
  .custom-btn-transperent {
      padding: 12px;
    }
  
  
  /* Karan Css Class Finish */
  
  

  .sample-card {
    position: relative;
    margin-right: 20px;
  }

  .sample-card .bg-purple-white {
    display: grid;
    align-items: start;
  }
}

@media screen and (min-width: 1024px) {
  
  .mnre_invert{
    filter: invert(0);
  }
}

/* for xl screen */
@media screen and (min-width: 1900px) {
  .sample-card {
    position: relative;
    width: 530px;
    ;
    margin-right: 20px;
  }

  .sample-card .bg-purple-white {
    display: grid;
    align-items: center;
  }

  .stickyCustom {
    position: sticky;
    top: 16rem;
    height: 80vh;
    width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    margin-top: 5rem;
  }

  .horizontal {
    margin-top: 5rem;
  }
}


/* this media query nly logos animation in a=every screen thats way I'm using the 0px in starting value start */

@media screen and (min-width: 0px) {

  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos ,.clients_slide{
    overflow: hidden;
    padding: 10px 0;
    background: #F4F4FC;
    white-space: nowrap;
    position: relative;
  }

  .logos-slide {
    display: inline-block;
    animation: 100s slide infinite linear;
  }
  
  .logos-slide img {
    max-height: 50px;
    max-width: 180px;
    margin: 0 30px;
  }



  /* client-logo slider start */





.wrapper_marquee {
  display: flex;
  flex-direction: column;
  gap: calc(clamp(10rem, 1rem + 40vmin, 30rem)/ 14);
  margin: auto;
  max-width: 100vw;
}


.marquee_logos {
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;          
  gap: calc(clamp(10rem, 1rem + 40vmin, 30rem)/ 14);
  }

.marquee_group_logos {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: calc(clamp(10rem, 1rem + 40vmin, 30rem)/ 14);
  min-width: 100%;
  -webkit-animation: scroll-x 20s linear infinite;
          animation: scroll-x 20s linear infinite;
}







@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - calc(clamp(10rem, 1rem + 40vmin, 30rem)/ 14)));
  }
}

@-webkit-keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - calc(clamp(10rem, 1rem + 40vmin, 30rem)/ 14)));
  }
}

  
  
  /* client-logo slider finish */
  
  



/*   

.mnre_invert{
  filter: invert(1);
} */

  
  
}


  .clients_slide .swiper-slide{background: transparent !important; width: 100%;}
  .clients_slide .swiper-slide img{max-width: 180px;}












/* this media query nly logos animation in a=every screen thats way I'm using the 0px in starting value  finish*/

