html {
  overflow: hidden scroll;
}

.js-scroll {
  z-index: 10;
  will-change: transform;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

svg textPath {
  text-transform: uppercase;
  letter-spacing: 20px;
  fill: #111;
  background: #333;
  font-family: sans-serif;
  font-size: 24px;
}

.text-animation {
  color: #333;
  white-space: nowrap;
  font-weight: 100;
  line-height: 1.5;
  position: relative;
  overflow: hidden;
}

.text-animation span {
  margin-left: 40px;
  font-size: 40px;
}

.text-animation .message {
  width: 830px;
  font-weight: 900;
  animation: 10s ease-in-out infinite openclose;
  display: block;
  position: absolute;
  overflow: hidden;
  transform: translateY(0%);
}

.text-animation .word1, .word2, .word3 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    transform: translateY(0%);
  }

  25% {
    opacity: 1;
    transform: translateY(-30%);
  }

  50% {
    opacity: 1;
    transform: translateY(-65%);
  }

  75% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: .2;
    transform: translateY(-130%);
  }
}

.season_tabs {
  height: auto;
  position: relative;
}

.ai-tabs {
  clear: both;
  min-height: auto;
  margin: 20px 0;
  position: relative;
}

.season_tab {
  width: 100%;
}

.season_tab label {
  vertical-align: middle;
  cursor: pointer;
  width: 20rem;
  height: 40px;
  margin-left: 0;
  line-height: 3rem;
  display: table-cell;
  position: relative;
  left: 1px;
}

.season_tab .environment {
  display: flex;
}

.season_tab [type="radio"] {
  display: none;
}

.season_content, .season_content_horizontal {
  background: #fff;
  position: absolute;
  top: 0;
  left: 40%;
  right: 0;
}

.terra_tabs {
  clear: both;
  min-height: 450px;
  margin: 20px 0;
  position: relative;
}

.terra_tab {
  float: left;
  clear: both;
  width: 100%;
}

.terra_tab label {
  width: 30rem;
  height: 40px;
  margin-left: 0;
  display: block;
  position: relative;
  left: 1px;
}

.season_content span {
  animation: .5s ease-out slideInFromTop;
}

[type="radio"]:checked ~ label {
  z-index: 2;
  color: #5c3eb3;
  background: #fff;
  border-bottom: 2px solid #5c3eb3;
}

[type="radio"]:checked ~ label ~ .season_content {
  z-index: 1;
}

[type="radio"]:checked ~ .environment {
  z-index: 2;
  color: #fff;
  background-color: #0000;
  border-bottom: 2px solid #fff;
}

.environment-contant {
  background-color: #5c3eb3;
}

.season-tabs-environment {
  min-height: 320px;
}

.season-tabs-education {
  min-height: 400px;
}

.grid-split {
  cursor: pointer;
  color: #fff;
  background-color: #5c3eb3;
  grid-column: span 2 / span 2;
}

.show {
  display: none;
}

.grid-split .show {
  display: block;
}

.custom-btn-1 {
  color: #fff;
  cursor: pointer;
  background: none;
  outline: none;
  font-family: Lato, sans-serif;
  font-weight: 500;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.custom-btn {
  color: #fff;
  cursor: pointer;
  background: none;
  outline: none;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: 300;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.custom-btn-transperent {
  color: #fff;
  cursor: pointer;
  background: none;
  outline: none;
  padding: 12px 25px;
  font-family: Lato, sans-serif;
  font-weight: 500;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.btn-16 {
  color: #000;
  z-index: 1;
  background-color: #0000;
  border: 1px solid #000;
  font-family: Gilroy-Medium;
  font-weight: 500;
}

.new_navbar_theme .btn-16 {
  color: #fff;
  z-index: 1;
  background-color: #0000;
  border: 1px solid #fff;
  font-family: Gilroy-Medium;
  font-weight: 500;
}

.new_btn_hire {
  background: #5c3eb3;
}

.new_btn_hire:hover {
  color: #fff;
  background: none;
}

.new_btn_hire:hover:after {
  width: 100%;
  left: 0;
}

.new_btn_hire:after {
  content: "";
  z-index: -1;
  background-image: linear-gradient(to right, #6b21a8, #8023b4, #9525bf, #aa26c9, #c026d3);
  border-radius: 5px;
  width: 0;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-16:after {
  content: "";
  z-index: -1;
  background-image: linear-gradient(to right, #6b21a8, #8023b4, #9525bf, #aa26c9, #c026d3);
  width: 0;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.new_navbar_theme .btn-16:hover:after {
  display: none;
}

.new_navbar_theme .btn-16:hover {
  color: #fff;
  border: 1px solid #fff;
}

.btn-16:hover:after {
  width: 100%;
}

.btn-16:active {
  top: 2px;
}

.btn-15 {
  z-index: 1;
  background: #5c3eb3;
  border: none;
}

.btn-15:after {
  content: "";
  z-index: -1;
  background-image: linear-gradient(to right, #6b21a8, #8023b4, #9525bf, #aa26c9, #c026d3);
  border-radius: 5px;
  width: 0;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-15:hover {
  color: #fff;
}

.btn-15:hover:after {
  width: 100%;
}

.btn-15:active {
  top: 2px;
}

.btn-17 {
  z-index: 1;
  background: none;
  border: none;
}

.btn-border, .btn-border:hover {
  border: 1px solid #fff;
}

.btn-17:after {
  content: "";
  z-index: -1;
  background-color: #fff;
  width: 0;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-17:hover {
  color: #8400ff;
}

.btn-17:hover:after {
  width: 100%;
}

.btn-17:active {
  top: 2px;
}

.btn-18 {
  z-index: 1;
  border: none;
}

.btn-18:after {
  content: "";
  z-index: -1;
  background: #fff;
  width: 0;
  height: 100%;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.btn-18:hover {
  color: #9906fb;
}

.btn-18:hover:after {
  width: 100%;
}

.btn-18:active {
  top: 2px;
}

.custom-btn_hire {
  cursor: pointer;
  outline: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.newsCard {
  cursor: pointer;
  width: 100%;
  position: relative;
}

.mask {
  background: linear-gradient(0deg, #0000009c, #0000);
  width: 100%;
  height: 30%;
  transition: all .5s;
  position: absolute;
  bottom: -5rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

.anim-plug:hover .mask {
  bottom: 0;
}

.text {
  color: #fff;
  font-size: 20px;
}

.text-2 {
  color: #000;
  padding: 2rem;
  font-size: 20px;
  position: absolute;
}

.centerContent {
  position: absolute;
  top: 50%;
  left: 50%;
}

.newsCard .image {
  object-fit: fill;
}

.container-con {
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  background-color: #f2f0ff;
  width: 100%;
  height: 0;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.container-con:hover .overlay {
  height: 100%;
}

.overlay2 {
  background-color: #e8e2f7;
  justify-content: center;
  width: 100%;
  height: 0;
  transition: all .3s;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.container-con:hover .overlay2 {
  height: 100%;
  box-shadow: 0 2px 8px #63636333;
}

.text3 {
  color: #000;
  padding: 1rem;
  font-size: 20px;
  position: absolute;
}

.customContainer {
  width: 1380px;
}

.show-on-hover {
  visibility: hidden;
  opacity: 0;
  -o-transition: .5s;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .6s;
}

#slideDown:hover .show-on-hover {
  opacity: 1;
  visibility: visible;
}

.scrolling-words-container {
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  display: flex;
}

.scrolling-words-box {
  height: 2.5rem;
  margin: auto;
  overflow: hidden;
}

.scrolling-words-box ul {
  padding: 0;
  animation: 5s infinite scrollUp;
}

.scrolling-words-box ul li {
  color: #aac77b;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  font-family: Gilroy-Bold;
  list-style: none;
  display: flex;
}

@keyframes scrollUp {
  10%, 20% {
    transform: translateY(-16.66%);
  }

  30%, 40% {
    transform: translateY(-32.32%);
  }

  50%, 60% {
    transform: translateY(-49.98%);
  }

  70%, 80% {
    transform: translateY(-66.64%);
  }

  90%, 100% {
    transform: translateY(-83.3%);
  }
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.slider {
  place-items: center;
  width: 100%;
  height: 70px;
  display: grid;
  position: relative;
  overflow: hidden;
}

.slider:before {
  top: 0;
  left: 0;
}

.slider:after {
  top: 0;
  right: 0;
  transform: rotateZ(180deg);
}

.slide-track {
  justify-content: space-evenly;
  width: 6300px;
  animation: 20s linear infinite scroll;
  display: flex;
}

.slide-track2 {
  justify-content: space-evenly;
  width: 6300px;
  animation: 15s linear infinite scroll2;
  display: flex;
}

.slide {
  cursor: pointer;
  place-items: center;
  width: 150px;
  height: 60px;
  transition: all .5s;
  display: grid;
}

.slide:hover {
  transform: scale(1);
}

.slider_bg {
  height: 35vh;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  max-width: 100% !important;
}

.slider-cards {
  display: flex;
}

.slider-card {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 0 10px #0000000d;
}

.slider-card .image img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-width: 100%;
}

.slider-card .content {
  padding: 10px;
}

.slider-card.is-loading .image, .slider-card.is-loading h2, .slider-card.is-loading .timeline, .slider-card.is-loading .author-img, .slider-card.is-loading .author-name, .slider-card.is-loading .date, .slider-card.is-loading .timing, .slider-card.is-loading p {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%) 0 0 / 200% 100%;
  animation: 1.5s linear infinite shine;
}

.slider-card.is-loading .author-name, .slider-card.is-loading .timing {
  width: 55%;
}

.slider-card.is-loading .image {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 200px;
}

.slider-card.is-loading h2 {
  height: 50px;
}

.slider-card.is-loading p, .slider-card.is-loading .timing {
  height: 30px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

#position_box .bg-violet:hover .simple_vaccancy {
  background: #6b4ac8;
}

.simple_vaccancy {
  height: 320px;
  position: relative;
}

.simple_vaccancy h3 {
  color: #aac77b;
}

.Apply-Career {
  border: 8px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 20px auto;
  animation: 2s linear infinite spin;
  position: absolute;
  top: 30%;
  left: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader_data {
  display: block;
}

.text_blue {
  color: #1c033d;
}

.pfF_first_section {
  background: url("top_bg_portfolio.d5dd15de.webp") top / cover no-repeat;
}

.marquee_parent {
  width: 100%;
  overflow-x: hidden;
}

.marquee_items_parent {
  white-space: nowrap;
  width: 100%;
  animation-name: infiniteScroll;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: flex;
}

.bg_purple {
  background: url("purple_background.e38167fc.webp") center / cover no-repeat;
}

.gradient_title {
  color: #313149;
  background: linear-gradient(#f9f9f9, #fff, #fffcfc) padding-box padding-box, linear-gradient(150deg, #f98edb 40%, #fff 60%) border-box;
  border: 1px solid #0000;
  border-radius: 40px;
  padding: 13px 36px;
  display: inline-block;
}

.core_team_parent {
  background: linear-gradient(#fff0 17%, #fff 40%), url("line_transparent.7b6bb000.webp");
}

.core_team {
  background-color: #0000;
  background-image: url("core_team_bg.03885d84.webp"), linear-gradient(0deg, #323232 0%, #000 100%), linear-gradient(135deg, #423fcc 2%, #ff7f56c1 31%, #323232 64%);
  background-position: 0 0, 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, border-box;
  background-clip: border-box, padding-box, border-box;
  border: 3px solid #0000;
  box-shadow: 0 30px 40px #13083147;
}

.circle_parent .circle_item:nth-child(odd) .circle_gradient {
  background: linear-gradient(90deg, #272727, #141414) padding-box padding-box, linear-gradient(87deg, #414141 40%, #fff0 55%) border-box;
}

.circle_parent .circle_item:nth-child(2n) .circle_gradient {
  background: linear-gradient(270deg, #272727, #141414) padding-box padding-box, linear-gradient(272deg, #414141 47%, #fff0 55%) border-box;
}

.circle_parent .circle_item:nth-child(odd):after {
  content: "";
  z-index: 9;
  background: #414141;
  width: 150%;
  height: 1px;
  position: absolute;
  bottom: -.4px;
  left: 44%;
}

.circle_parent .circle_item:nth-child(2n):before {
  content: "";
  z-index: 9;
  background: #414141;
  width: 150%;
  height: 1px;
  position: absolute;
  top: -.4px;
  bottom: .6px;
  left: 44%;
}

.circle_parent .circle_item:last-child:before, .circle_parent:last-child:after {
  display: none;
}

.circle_gradient {
  text-wrap: nowrap;
  border: 1px solid #0000;
  padding: 67px 0;
}

.pepsico {
  background-image: url("line_transparent.7b6bb000.webp"), linear-gradient(#f7f1fb, #f7f1fb);
  background-position: 11% 0;
  background-repeat: no-repeat;
}

.fountain_life {
  background-image: url("fountain_background.afe7a942.webp");
  background-position: 90% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.shl {
  background-image: url("line_transparent.7b6bb000.webp"), linear-gradient(#f7f1fb, #f7f1fb);
  background-position: 85% 0;
  background-repeat: no-repeat;
}

.safehouse {
  background-image: url("safehouse_background.78c00187.webp"), linear-gradient(#fff, #fff);
  background-position: 21% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.worship {
  background-image: url("worship_background.e4e5a2bb.webp"), linear-gradient(#f7f1fb, #f7f1fb);
  background-position: 87% -31%;
  background-repeat: no-repeat;
}

.myysports {
  background-image: url("line_transparent.7b6bb000.webp"), linear-gradient(#fff, #fff);
  background-position: 11% 0;
  background-repeat: no-repeat;
}

.coinigy {
  background-image: url("fountain_background.afe7a942.webp");
  background-position: 80% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.jasiri {
  background-image: url("line_transparent.7b6bb000.webp"), linear-gradient(#f7f1fb, #f7f1fb);
  background-position: 85% 0;
  background-repeat: no-repeat;
}

.eseo {
  background-image: url("line_transparent.7b6bb000.webp"), linear-gradient(#fff, #fff);
  background-position: 11% 0;
  background-repeat: no-repeat;
}

.housing_marking {
  background-image: url("safehouse_background.78c00187.webp"), linear-gradient(#fff, #fff);
  background-position: 70% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.chargever {
  background-image: url("worship_background.e4e5a2bb.webp"), linear-gradient(#f7f1fb, #f7f1fb);
  background-position: 0 -45%;
  background-repeat: no-repeat;
}

.obodo {
  background-image: url("line_transparent.7b6bb000.webp"), linear-gradient(#fff, #fff);
  background-position: 85% 0;
  background-repeat: no-repeat;
}

.swift_digital {
  background-image: url("fountain_background.afe7a942.webp");
  background-position: 10% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.smogwise {
  background-image: linear-gradient(#f5f5f57d, #f5f5f57d);
}

.learnloft {
  background-image: url("learnloft_background.1ff6548f.webp"), linear-gradient(#010101, #000);
  background-position: 95% 43%;
  background-repeat: no-repeat;
}

.adapt {
  background-image: url("fountain_background.afe7a942.webp");
  background-position: 80% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.quixy_hamta {
  background-image: linear-gradient(#f5f5f57d, #f5f5f57d);
}

.shl_black {
  background-image: url("shl_background.94e11bc9.webp"), linear-gradient(#010101, #000);
  background-position: 15% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.delivery_result {
  background: linear-gradient(to bottom right, #1c033d, #0f0221d9);
}

.circle_purple {
  z-index: -1;
  background: linear-gradient(to bottom right, #1c033dd9, #0f0221d9) padding-box padding-box, linear-gradient(87deg, #797979d9 -160%, #fff0 70%) border-box;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 801px;
  height: 801px;
  top: -150px;
  right: -90px;
}

.sm_circle {
  background: linear-gradient(221deg, #1c033dd9 60%, #0f0221d9 90%) padding-box padding-box, linear-gradient(87deg, #797979d9 -160%, #fff0 70%) border-box;
  border: 2px solid #0000;
}

.circle_dark {
  z-index: -1;
  background: linear-gradient(360deg, #0c0c0c, #121212) padding-box padding-box, linear-gradient(87deg, #000 63%, #434343 70%) border-box;
  border: 1px solid #0000;
  border-radius: 50%;
  width: 801px;
  height: 801px;
  top: -200px;
  left: -40px;
}

.track_record {
  background: #000;
}

.apps_title:before {
  content: "";
  background-color: #3e3e3e;
  width: 19%;
  height: 1px;
  position: absolute;
  top: 18px;
  left: 145px;
}

.learnloft .apps_title:before {
  background-color: #fff;
}

.fountain_life .apps_title_2:before, .jasiri .apps_title_2:before, .smogwise .apps_title_2:before {
  content: "";
  background-color: #3e3e3e;
  width: 19%;
  height: 1px;
  position: absolute;
  top: 18px;
  left: 252px;
}

@keyframes infiniteScroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1500px);
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-750px);
  }
}

.customSlide {
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all .2s;
  position: relative;
  overflow: hidden;
}

.customSlide:after {
  content: "";
  transform-origin: bottom;
  z-index: -1;
  background: #5c3eb3;
  width: 100%;
  height: 100%;
  transition: transform .2s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleY(0);
}

.customSlide:hover:after {
  transition: all .35s;
  transform: scaleY(1);
}

.custom_list_item li:before {
  content: url("red-circle.16fc83ea.svg");
}

.custom_list_item li {
  gap: 1rem;
  margin-bottom: .7rem;
  display: flex;
}

.containerCustom {
  width: 100%;
  position: relative;
}

.space-holder {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.stickyCustom {
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  position: sticky;
  top: 10rem;
  overflow: hidden;
}

.horizontal {
  will-change: transform;
  position: absolute;
}

.cards {
  grid-template-columns: repeat(6, 1fr);
  height: 100%;
  display: grid;
  position: relative;
}

.sample-card {
  width: 430px;
  margin-right: 20px;
  display: grid;
  position: relative;
}

.accordion__content ul {
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.mnre_invert {
  filter: invert();
}

@media screen and (width >= 0) and (width <= 456px) {
  .mask {
    position: absolute;
    bottom: 0;
  }

  .mnre_invert {
    filter: invert(0);
  }

  .slider_bg {
    height: 45vh;
  }
}

@media screen and (width <= 768px) {
  .custom-btn, .custom-btn-transperent {
    padding: 10px 20px;
  }

  .customContainer {
    width: 100%;
  }

  .slide-track, .slide-track2 {
    width: 3750px;
  }

  .slide {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-800px);
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-400px);
    }
  }

  .scrolling-words-container {
    font-size: 1.3rem;
  }

  .scrolling-words-box, .scrolling-words-box ul li {
    height: 2rem;
  }

  .slider_bg {
    height: 40vh;
  }
}

@media screen and (width <= 991px) {
  .stickyCustom {
    height: 100vh;
    position: sticky;
    top: 0;
  }

  .bg-contact-convo form label {
    display: inline-block;
  }

  .bg-contact-convo form input {
    margin-bottom: 1rem;
  }

  .accordion__content ul {
    margin-bottom: 1rem;
    padding-left: 1rem;
  }

  .sample-card {
    min-width: 360px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: grid;
    position: relative;
  }
}

@media screen and (width <= 1024px) {
  .newsCard .image {
    min-height: 220px;
  }
}

@media screen and (width <= 1366px) {
  .stickyCustom {
    height: 100vh;
    position: sticky;
    top: 6rem;
  }

  .containerCustom > .space-holder > .stickyCustom {
    height: 90vh;
    position: sticky;
    top: 4rem;
  }

  .custom-btn-transperent {
    padding: 12px;
  }

  .sample-card {
    margin-right: 20px;
    position: relative;
  }

  .sample-card .bg-purple-white {
    align-items: start;
    display: grid;
  }
}

@media screen and (width >= 1024px) {
  .mnre_invert {
    filter: invert(0);
  }
}

@media screen and (width >= 1900px) {
  .sample-card {
    width: 530px;
    margin-right: 20px;
    position: relative;
  }

  .sample-card .bg-purple-white {
    align-items: center;
    display: grid;
  }

  .stickyCustom {
    width: 100%;
    height: 80vh;
    margin: 5rem auto 0;
    position: sticky;
    top: 16rem;
    overflow-x: hidden;
  }

  .horizontal {
    margin-top: 5rem;
  }
}

@media screen and (width >= 0) {
  @keyframes slide {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .logos, .clients_slide {
    white-space: nowrap;
    background: #f4f4fc;
    padding: 10px 0;
    position: relative;
    overflow: hidden;
  }

  .logos-slide {
    animation: 100s linear infinite slide;
    display: inline-block;
  }

  .logos-slide img {
    max-width: 180px;
    max-height: 50px;
    margin: 0 30px;
  }

  .wrapper_marquee {
    flex-direction: column;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    max-width: 100vw;
    margin: auto;
    display: flex;
  }

  .marquee_logos {
    -webkit-user-select: none;
    user-select: none;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    display: flex;
    overflow: hidden;
  }

  .marquee_group_logos {
    flex-shrink: 0;
    justify-content: space-around;
    align-items: center;
    gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
    min-width: 100%;
    animation: 20s linear infinite scroll-x;
    display: flex;
  }

  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(calc(-100% + clamp(10rem, 1rem + 40vmin, 30rem) / -14));
    }
  }
}

.clients_slide .swiper-slide {
  width: 100%;
  background: none !important;
}

.clients_slide .swiper-slide img {
  max-width: 180px;
}
/*# sourceMappingURL=about-us.4158ebf8.css.map */
